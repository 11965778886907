import React from 'react'
import Button from '../../components/FormElements/Button'
import { setPageTitle } from '../../utils'
import classes from './styles.module.scss'


const NotFound = ({ history }) => {

  setPageTitle("Page not found")

  return (
    <div className={classes.container}>
      <h2 className={classes.notFound_404}>404</h2>
      <h2 className={classes.notFound_404__title}>Page not found</h2>
      <p>Sorry! The page you're looking for doesn't exist.</p>
      <Button
        onClick={() => history.push("/my-dashboards")}
        text="Go Home"
      />
    </div>
  )

}

export default NotFound