import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import theme from "./styles/theme";
import "./styles/style.scss";
import { MuiThemeProvider } from "@material-ui/core/styles";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Provider } from "react-redux";
import store from "./store/store";

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <Routes />
    </MuiThemeProvider>
  </Provider>,
  document.querySelector("#root")
);
