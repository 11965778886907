import { getHeaders } from "../../utils";
import axios from "../../utils/axios";
import * as Types from "./actionTypes";
import queryString from 'query-string'


export const login = (loginCreds, history) => (dispatch) => {
    dispatch({ type: Types.AUTH_LOADING, payload: true });
    dispatch({ type: Types.USER_LOGIN_ERROR, payload: {} });

    const url = queryString.parse(history.location.search).return_url || "/"

    axios
        .post("/auth/login/", loginCreds)
        .then((res) => {
            localStorage.setItem("dashUserToken", res.data.key);
            dispatch({ type: Types.USER_LOGGED_IN });
            history.push(url);
        })
        .catch((error) => {
            dispatch({
                type: Types.USER_LOGIN_ERROR,
                payload: error.response ? error.response.data : {},
            });
            dispatch({ type: Types.AUTH_LOADING, payload: false });
        });
};

export const register = (registerData, history) => (dispatch) => {
    dispatch({ type: Types.AUTH_LOADING, payload: true });
    // dispatch({ type: Types.USER_REGISTER_ERROR, payload: {} });

    const url = queryString.parse(history.location.search).return_url || "/"

    axios
        .post("/auth/register/", registerData)
        .then((res) => {
            localStorage.setItem("dashUserToken", res.data.key);
            dispatch({ type: Types.USER_REGISTERED });
            history.push(url);
            dispatch({ type: Types.REGISTER_POPUP_TOGGLER, payload: true });
        })
        .catch((error) => {
            console.log(error.response)
            dispatch({
                type: Types.USER_REGISTER_ERROR,
                payload: error.response ? error.response.data : {},
            });
            dispatch({ type: Types.AUTH_LOADING, payload: false });
        });
};

export const logout = (history) => (dispatch) => {
    dispatch({ type: Types.AUTH_LOADING, payload: true });

    axios
        .post("/auth/logout/", {}, { headers: getHeaders() })
        .then((res) => {
            localStorage.removeItem("dashUserToken");
            dispatch({ type: Types.USER_LOGGED_OUT });
            dispatch({ type: Types.AUTH_LOADING, payload: false });
        })
        .catch((error) => {
            console.log(error.response);
            dispatch({
                type: Types.USER_LOGOUT_ERROR,
                payload: error.response ? error.response.data : {},
            });
            dispatch({ type: Types.AUTH_LOADING, payload: false });
        });
};



