import { combineReducers } from "redux";

// reducers
import themeReducer from "./themeReducers";
import authReducer from "./authReducer";

const rootReducers = combineReducers({
  theme: themeReducer,
  auth: authReducer
});

export default rootReducers;
