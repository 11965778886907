import axios from 'axios'
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});

instance.defaults.xsrfHeaderName = "X-CSRFTOKEN";
instance.defaults.xsrfCookieName = "Csrftoken";
instance.defaults.withCredentials = true;

if (localStorage.getItem('access_token')) {
  instance.defaults.headers.common['Authorization'] = "JWT " + localStorage.getItem('access_token');
}

instance.interceptors.response.use(
  response => response,
  async function (error) {
    const originalRequest = error.config;
    if (originalRequest.url === 'api/auth/users/refresh/') {
      // return;
    } else {
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refresh_token = localStorage.getItem('refresh_token');
        try {
          const refreshResp = await instance
            .post('api/auth/users/refresh/', { refresh: refresh_token })
          localStorage.setItem('access_token', refreshResp.data.access);
          localStorage.setItem('refresh_token', refreshResp.data.refresh);
          instance.defaults.headers['Authorization'] = "JWT " + refreshResp.data.access;
          originalRequest.headers['Authorization'] = "JWT " + refreshResp.data.access;
          return instance(originalRequest);
        } catch (err) {
          cookies.remove('userId');
          cookies.remove('userRole');
          cookies.remove('homeRoute');
          localStorage.clear();
        };
      }
      if (error.response.status === 403) {
        window.location.href = '/access-denied';
      }
      else {
        return Promise.reject(error)
      };
    }
  }
);



export default instance