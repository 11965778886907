import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import dateImg from "../../assets/images/registerPage.svg"
import { Link } from "react-router-dom";
import classes from "./registerStyles.module.scss";
import { setPageTitle, getUserLocationDetails } from "../../utils";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { register } from "../../store/actions/authActions"

const ErrorMessage = ({ message, style }) => (
    <div className={classes.form_message__error}>
        <p style={style}>{message} &nbsp;</p>
    </div>
)

const RegisterPage = ({ history }) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    setPageTitle("Register");

    // states
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [disable, setDisable] = useState(false);
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [countryCode, setCountryCode] = useState("US");

    const validateEmail = () => {
        if (email.length > 0)
            if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
                setErrors((prev) => ({
                    ...prev,
                    email: "Please enter valid email address"
                }))
            } else {
                setErrors((prev) => ({
                    ...prev,
                    email: ""
                }))
            }
    }

    const validateName = () => {
        if (!fullName && fullName.length > 0) {
            setErrors((prev) => ({
                ...prev,
                fname: "Name cannot be empty"
            }))
        } else {
            setErrors((prev) => ({
                ...prev,
                fname: ""
            }))
        }
    }
    // handlers
    const submitHandler = (event) => {
        event.preventDefault();
        setDisable(true);

        const registerData = {
            email,
            password1: password,
            password2: password,
            full_name: fullName,
            phone,
            registration_url: window.location.href,
        };
        dispatch(register(registerData, history));
    };

    useEffect(() => {
        if (!!auth.registerErrors.non_field_errors) {
            setMessage(auth.registerErrors.non_field_errors[0]);
        }
        if (!auth.loading) {
            setDisable(false);
        }
    }, [auth]);

    useEffect(() => {
        if (phone) {
            if (isValidPhoneNumber(phone)) {
                setErrors({
                    ...errors,
                    phone: "",
                });
            } else {
                setErrors({
                    ...errors,
                    phone: "Invalid phone number",
                });
            }
        }

        // eslint-disable-next-line
    }, [phone]);

    useEffect(() => {
        getUserLocationDetails()
            .then((data) => {
                setCountryCode(data?.country?.iso_code || "US");
            })
            .catch((error) => setCountryCode("US"));
    }, []);

    return (
        <div className={classes.authMain}>
            <div
                className={`${classes.authContainer}`}
            >
                <div className={classes.authContainer_leftSide}>
                    <img
                        className={classes.authContainer_image}
                        src={dateImg}
                        alt="Date Range"
                    />
                </div>
                <div className={classes.authContainer_rightSide}>
                    <h2 className={classes.authContainer_rightSide__header}>
                        Create a new account
                    </h2>

                    <form onSubmit={submitHandler}>
                        <div className={classes.inputContainer}>
                            <label className={classes.inputContainer_label}>
                                Email
                            </label>
                            <input
                                type="email"
                                className={classes.inputContainer_input}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={validateEmail}
                                required
                            />
                            {auth.registerErrors.email && (
                                <ErrorMessage message={auth.registerErrors.email} />
                            )}
                            {errors.email && (
                                <ErrorMessage message={errors.email} />
                            )}
                        </div>
                        <div className={classes.inputContainer}>
                            <label className={classes.inputContainer_label}>
                                Password
                            </label>
                            <input
                                autoComplete="new-password"
                                type="password"
                                className={classes.inputContainer_input}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            {auth.registerErrors.password1 && (
                                <ErrorMessage message={auth.registerErrors.password1[0]} />
                            )}
                        </div>
                        <div className={classes.inputContainer}>
                            <label className={classes.inputContainer_label}>
                                Full name
                            </label>
                            <input
                                type="text"
                                className={classes.inputContainer_input}
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                onBlur={() => { validateName() }}
                                required
                            />
                            {auth.registerErrors.first_name && (
                                <ErrorMessage message={auth.registerErrors.first_name} />
                            )}
                            {errors.fname && (
                                <ErrorMessage message={errors.fname} />
                            )}
                        </div>

                        <div className={classes.inputContainer}>
                            <label className={classes.inputContainer_label}>
                                Phone number
                            </label>
                            <PhoneInput
                                className={classes.inputContainer_input}
                                value={phone}
                                onChange={setPhone}
                                defaultCountry={countryCode}
                                countryCallingCodeEditable={true}
                                international
                            />
                            {auth.registerErrors.phone && (
                                <ErrorMessage message={auth.registerErrors.phone} />
                            )}
                            {errors.phone && (
                                <ErrorMessage message={errors.phone} />
                            )}
                        </div>
                        <button
                            className={classes.authContainer_submitBtn}
                            disabled={
                                disable ||
                                !Object.values(errors).every(
                                    (x) => x === null || x === "" || x === undefined
                                )
                            }
                            type="submit"
                        >
                            {auth.loading ? "Please wait..." : "Create account"}
                        </button>
                    </form>

                    {message && <p style={{ color: "red" }}>{message}</p>}

                    <div className={classes.authContainer_linkText}>
                        Already have an account?{" "}
                        <Link
                            to={{
                                pathname: "/",
                                search: history.location.search,
                            }}
                            className={classes.authContainer_link}
                        >
                            Login Here
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
