import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import withSuspense from './withSuspense';
import { useSelector } from 'react-redux'
import queryString from 'query-string'

import NotFound from "./pages/StaticPages/NotFound";
import TermsConditionsPage from "./pages/StaticPages/TermsAndCondition";
import PrivacyPage from "./pages/StaticPages/PrivacyPolicy";
import AccessDenied from "./pages/StaticPages/AccessDenied";
import Register from "./pages/Auth/Register"

const SuperSapiensDash = React.lazy(() => import('./components/dashs/SS_Dash/Main'));
const MyDashboard = React.lazy(() => import("./pages/Dashboard/Dashboards"));
const LoginComponent = React.lazy(() => import("./pages/Auth/Login/LoginPage"));

function Router() {

  return (
    <BrowserRouter>
      <Switch>
        <GuestRoute exact path="/" component={withSuspense(LoginComponent)} />
        <PrivateRoute
          exact
          path="/ab/dashboards/dash"
          component={withSuspense(SuperSapiensDash)}
        />

        <PrivateRoute
          exact
          path="/my-dashboards"
          component={withSuspense(MyDashboard)}
        />

        <GuestRoute
          exact
          path="/register"
          component={withSuspense(Register)}
        />
        <GuestRoute exact path="/access-denied" component={AccessDenied} />
        <GuestRoute exact path="/terms" component={TermsConditionsPage} />
        <GuestRoute exact path="/privacy-policy" component={PrivacyPage} />
        <GuestRoute component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}


const GuestRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {!auth.isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/my-dashboards",
                search: props.location.search,
                state: {
                  from: props.location,
                  search: props.location.search,
                },
              }}
            />
          )}
        </>
      )}
    />
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {

        const params = { ...queryString.parse(props.location.search), return_url: props.location?.pathname || "/" }

        return (
          <>
            {auth.isAuthenticated ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  search: queryString.stringify(params),
                  state: { from: props.location },
                }}
              />
            )}
          </>
        )
      }}
    />
  );
};

export default Router;
