import React from "react";
import Button from '../../components/FormElements/Button'
import { setPageTitle } from "../../utils";
import classes from './styles.module.scss'

const AccessDenied = ({ history }) => {
  setPageTitle("Access Denied")

  return (
    <div className={classes.container}>
      <h2>You're not allowed in this page!</h2>
      <Button
        onClick={() => history.push("/my-dashboards")}
        text="Go Home"
      />
    </div>
  )
}

export default AccessDenied

