import axiosConfig from '../../utils/axios-config'


const apiServices = {
  endpoints() {
    return {
      passwordResetRequest: (obj) => axiosConfig.post('/api/auth/reset-password/request/', obj),
      resetPassword: (obj) => axiosConfig.post('/api/auth/reset-password/confirm/', obj),
      login: (obj) => axiosConfig.post("/api/auth/users/login/", obj),
      me: () => axiosConfig.get("/api/auth/users/me/"),
      refreshToken: () => axiosConfig.get("/api/auth/users/refresh/"),
      register: (obj) => axiosConfig.post("/api/auth/users/register/", obj),
    }
  }
}

export default apiServices
