import * as Types from "../actions/actionTypes";

const initialState = {
    isAuthenticated: !!localStorage.getItem("dashUserToken"),
    loginErrors: {},
    registerErrors: {},
    logoutErrors: {},
    resetPasswordErrors: {},
    loading: false,
    passwordResetEmailSent: false,
    passwordResetted: false,
    user: {
        connect_status: true,
        templates: [],
    },
    isResetEmailSent: false,
    isForgotPOpened: false,
    isForgotPasswordFailed: false,
    changePassword: {
        errors: {},
        success: false,
    },
    updateProfile: {
        errors: {},
        success: false,
    },
    usernameGuessed: {
        loading: false,
        username: "",
    },
    isRegisterPopupShown: false,
};

function authReducer(state = initialState, action) {
    switch (action.type) {
        case Types.USER_LOGGED_IN: {
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                loginErrors: {},
                user: action.payload ? action.payload : { ...state.user },
            };
        }
        case Types.USER_LOGIN_ERROR: {
            return {
                ...state,
                loginErrors: action.payload,
            };
        }
        case Types.USER_REGISTERED: {
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                registerErrors: {},
            };
        }
        case Types.USER_REGISTER_ERROR: {
            return {
                ...state,
                registerErrors: action.payload,
            };
        }
        case Types.AUTH_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case Types.USER_LOGGED_OUT: {
            return {
                ...state,
                loading: true,
                isAuthenticated: false,
            };
        }
        case Types.USER_LOGOUT_ERROR: {
            return {
                ...state,
                logoutErrors: action.payload,
            };
        }
        case Types.GENERATE_USERNAME_LOADING: {
            return {
                ...state,
                usernameGuessed: {
                    ...state.usernameGuessed,
                    loading: action.payload,
                },
            };
        }
        case Types.GENERATE_USERNAME_LOADED: {
            return {
                ...state,
                usernameGuessed: {
                    ...state.usernameGuessed,
                    username: action.payload,
                },
            };
        }
        default:
            return state;
    }
}

export default authReducer;
