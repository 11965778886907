import React, { Component } from "react";
import Cookies from "universal-cookie";
import "../styles/style.scss";
import LoginService from "../pages/Auth/ApiService";

const cookies = new Cookies();

function LoadingMessage() {
  return (
    <div className="splash-screen" style={{ background: "#2F383D" }}>
      <span className="loading-text">Loading ...</span>
      <div className="loading-dot">.</div>
    </div>
  );
}

function withSplashScreen(WrappedComponent) {

  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    componentDidMount() {
      if (
        localStorage.getItem("refresh_token") &&
        cookies.get("userId") !== undefined
      ) {
        this.checkUserSession();
      }
      setTimeout(() => {
        this.setState({
          loading: false,
        });
      }, 1500);
    }

    checkUserSession = async () => {
      try {
        const data = await LoginService.endpoints().me();
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 1500);

        cookies.set("userDashes", data.data.dashes || []);
        this.props.history.push("my-dashboards");
      } catch (error) {
        cookies.set("userId", null, { path: "/" });
        cookies.remove("userId");
        cookies.remove("userRole");
        cookies.remove("homeRoute");
        this.setState({
          loading: false,
        });
      }
    };

    render() {
      // while checking user session, show "loading" message
      if (this.state.loading) return LoadingMessage();

      // otherwise, show the desired route
      return <WrappedComponent {...this.props} />;
    }
  };
}

export { LoadingMessage }
export default withSplashScreen;
