import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: { main: '#00AEFF' },
    secondary: { main: '#00AEFF', light: '#8b8585' },
    error: { main: '#ea3636', light: '#e57373', dark: '#d32f2f' }
  },
});


export default theme;
