import axios from "axios";

export function getHeaders(additional) {
  const dashUserToken = localStorage.getItem('dashUserToken')
  const timezoneOffset = new Date().getTimezoneOffset()

  let headers = {
    ...additional,
    "X-Api-Timezone-Offset": timezoneOffset
  }

  if (dashUserToken) {
    headers["Authorization"] = `Token ${dashUserToken}`
  }

  return headers
}

export const createUUID = () => {
  // used to generate a random UUID (for key props)
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
    return v.toString(16);
  });
}


export const setPageTitle = (title) => {
  const siteName = process.env.REACT_APP_WEBSITE_NAME
  if (title) {
    document.title = title + " — " + siteName
  } else {
    document.title = siteName
  }
  return true
}

export const getNumberRange = (lower, upper) => {
  return Array.from(new Array(upper), (x, i) => i + lower)
}

export const getUserLocationDetails = async () => {
  const API_KEY = "d011aa6de6644cd093dbd2111eb59286";
  const url = `https://api.geoapify.com/v1/ipinfo?apiKey=${API_KEY}`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log("Error occured while getting user location info");
    return {};
  }
};